import { LOCAL_STORAGE_PREFIX } from '../routes/constants';

const localStorageService = {
  // Add a prefix to the provided key
  addPrefix: (key) => `${LOCAL_STORAGE_PREFIX}${key}`,

  // Process expired keys and remove them
  processExpiredKeys: () => {
    try {
      for (const key in localStorage) {
        if (key.startsWith('masjid-care-')) {
          const record = JSON?.parse(localStorage?.getItem(key));
          if (
            record &&
            record.expiration &&
            new Date()?.getTime() >= record.expiration
          ) {
            localStorage?.removeItem(key);
          }
        }
      }
    } catch (error) {
      console.error('Error processing expired keys in localStorage:', error);
    }
  },

  // Get data from localStorage
  getItem: (key) => {
    localStorageService?.processExpiredKeys();
    try {
      const prefixedKey = localStorageService?.addPrefix(key);
      const record = JSON?.parse(localStorage?.getItem(prefixedKey));
      if (!record || typeof record !== 'object') {
        return undefined;
      }
      return JSON?.parse(record.value);
    } catch (error) {
      console.error('Error getting data from localStorage:', error);
      return undefined;
    }
  },

  // Set data in localStorage with optional expiration time in minutes
  setItem: (key, data, time = 10080) => {
    localStorageService?.processExpiredKeys();
    try {
      const prefixedKey = localStorageService?.addPrefix(key);
      const serializedData = JSON?.stringify(data);
      const record = {
        value: serializedData,
        expiration: new Date()?.getTime() + time * 60 * 1000,
      };
      localStorage?.setItem(prefixedKey, JSON?.stringify(record));
    } catch (error) {
      console.error('Error setting data in localStorage:', error);
    }
  },

  // Delete data from localStorage
  removeItem: (key) => {
    try {
      const prefixedKey = localStorageService?.addPrefix(key);
      localStorage?.removeItem(prefixedKey);
    } catch (error) {
      console.error('Error removing data from localStorage:', error);
    }
  },
  removeAllWithPrefix: () => {
    try {
      for (const key in localStorage) {
        if (key.startsWith(LOCAL_STORAGE_PREFIX)) {
          localStorage?.removeItem(key);
        }
      }
    } catch (error) {
      console.error(
        'Error removing items with prefix from localStorage:',
        error,
      );
    }
  },
};

export default localStorageService;
