import styled from 'styled-components';

export const StatusWrapper = styled.small`
  display: inline-flex;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 8px;
  &.completed {
    background-color: #6ac259;
    color: #fff;
  }
  &.initiated {
    background-color: #ccc;
    color: #333;
  }
`;
