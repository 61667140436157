import React, { useEffect } from 'react';
import { LoginWrapper, FormWrapper } from './login-styles';
import { Form, Input, Typography, Button } from 'antd';
import { loginAsync } from './auth-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectAuthLoading, selectAuthToken } from './auth-selectors';
import { fetchMasjidData } from '../profile/profile-slice';
import localStorageService from '../../../services/localStorageService';
import { MASJID_UUID } from '../../../utils/constants';
import masjidCareLogo from '../../../assets/masjid-care/masjid-care-logo-ref-3-dark-1.png';

const { Title } = Typography;

const Login = () => {
  const authLoading = useSelector(selectAuthLoading);
  const authToken = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    const { email, password } = values;
    await dispatch(loginAsync({ email, password }));
    const masjidUuid = localStorageService?.getItem(MASJID_UUID);
    if (masjidUuid) {
      await dispatch(fetchMasjidData({ masjidUid: masjidUuid }));
    }
    navigate('/dashboard/');
  };

  useEffect(() => {
    authToken && navigate('/dashboard/');
  }, []);

  return (
    <LoginWrapper>
      <FormWrapper>
        <div className="masjid-care-logo">
          <img src={masjidCareLogo} alt="Masjid Care" />
        </div>
        <Title className="font-regular text-muted text-center title" level={5}>
          Enter your email and password to sign in
        </Title>
        <Form name="login" onFinish={onFinish}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter a valid email address',
                type: 'email',
              },
            ]}
          >
            <Input placeholder="Email Address" autoComplete="off" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter your password!' }]}
          >
            <Input.Password placeholder="Password" autoComplete="off" />
          </Form.Item>

          <Form.Item>
            {authLoading ? (
              <Button block type="primary">
                Please wait...
              </Button>
            ) : (
              <Button block type="primary" htmlType="submit">
                Log in
              </Button>
            )}
          </Form.Item>
        </Form>
      </FormWrapper>

      {/* <ImageWrapper>
        <img src={masjidIllustration} />
      </ImageWrapper> */}
    </LoginWrapper>
  );
};

export default Login;
