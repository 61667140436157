import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthToken } from '../../pages/login/auth-selectors';

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const storeToken = useSelector(selectAuthToken);

  useEffect(() => {
    if (storeToken) {
      setIsAuthenticated(true);
      navigate('/dashboard/');
    } else {
      setIsAuthenticated(false);
      navigate('/login');
    }
  }, []);

  return isAuthenticated ? <>{children}</> : null;
};

export default PrivateRoute;
