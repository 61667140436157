import React, { useEffect, useState } from 'react';
import AppLayout from '../../components/layout/layout';
import { Card, Col, DatePicker, Row, Space, Typography, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDashboardChartData, fetchDashboardData } from './dashboard-slice';
import {
  selectDashboardChartData,
  selectDashboardChartLoading,
  selectDashboardLoading,
  selectDashboardsData,
} from './dashboard-selectors';
import { formatCurrencyINR } from '../../../utils';
import {
  ChartSkeleton,
  DashboardCardSkeleton,
  DashboardWrapper,
} from './dashboard-styles';
import LineChart from '../../components/chart/line-chart';

import moment from 'moment';
import { selectMasjidDetails } from '../profile/profile-selectors';
import { fetchMasjidData } from '../profile/profile-slice';
import { selectMasjidUuid } from '../login/auth-selectors';

const { RangePicker } = DatePicker;

const Dashboard = () => {
  const dispatch = useDispatch();
  const dashboardLoading = useSelector(selectDashboardLoading);
  const dashboardData = useSelector(selectDashboardsData);

  const dashboardChartLoading = useSelector(selectDashboardChartLoading);
  const dashboardChartData = useSelector(selectDashboardChartData);

  const masjidUuid = useSelector(selectMasjidUuid);
  const masjidDetails = useSelector(selectMasjidDetails);

  const [customDateFilter, setCustomDateFilter] = useState(false);
  const [processedChartData, setProcessedChartData] = useState(null);

  const { Title, Text } = Typography;

  const handleDateChange = (date, dateString) => {
    const query =
      `?dates[from]=${dateString[0]}` + `&dates[to]=${dateString[1]}`; // dateString[0] + "," + dateString[1];
    if (date) {
      dispatch(fetchDashboardData({ dateRange: query }));
    } else {
      dispatch(fetchDashboardData());
    }
  };

  const handleFilterByChange = (value) => {
    switch (value) {
      case 'custom':
        setCustomDateFilter(true);
        break;
      case '60':
        setCustomDateFilter(false);
        dispatch(fetchDashboardData({ dateRange: '?days=60' }));
        break;
      case '90':
        setCustomDateFilter(false);
        dispatch(fetchDashboardData({ dateRange: '?days=90' }));
        break;
      default:
        setCustomDateFilter(false);
        dispatch(fetchDashboardData({ dateRange: '?days=30' }));
        break;
    }
  };

  const fetchMasjidDataFromAPi = async () => {
    await dispatch(fetchMasjidData({ masjidUid: masjidUuid }));
  };

  useEffect(() => {
    if (dashboardData === null) {
      dispatch(fetchDashboardData());
    }
    if (dashboardChartData === null) {
      const today = moment()?.format('YYYY-MM-DD');
      const thirtyDaysAgo = moment()
        ?.subtract(30, 'days')
        ?.format('YYYY-MM-DD');
      dispatch(fetchDashboardChartData(thirtyDaysAgo + ',' + today));
    }
    if (!masjidDetails) {
      fetchMasjidDataFromAPi();
    }
  }, []);

  useEffect(() => {
    if (dashboardChartData) {
      // Create a data structure to store the totals by date and type
      const dataByDateAndType = {};
      dashboardChartData?.forEach((donation) => {
        const date = new Date(donation?.initiated_at).toLocaleDateString();

        if (!dataByDateAndType[date]) {
          dataByDateAndType[date] = {
            date,
            zakat: 0,
            sadaqa: 0,
          };
        }
        dataByDateAndType[date][donation?.type] += donation?.amount;

        // Convert the data to an array
        const chartData = Object?.values(dataByDateAndType);
        // Sort the data by date (if needed)
        chartData.sort((a, b) =>
          moment(a.date, 'DD/MM/YYYY').diff(moment(b.date, 'DD/MM/YYYY')),
        );

        if (chartData) {
          const series = [
            {
              name: 'Zakat',
              data: chartData?.map((item) => item?.zakat),
            },
            {
              name: 'Sadaqa',
              data: chartData?.map((item) => item?.sadaqa),
            },
          ];

          const options = {
            chart: {
              type: 'line',
              height: 350,
            },
            xaxis: {
              categories: chartData?.map((item) => item?.date),
            },
          };
          setProcessedChartData({
            series,
            options,
          });
        }
      });
    }
  }, [dashboardChartData]);

  return (
    <AppLayout>
      <DashboardWrapper>
        <div className="layout-content">
          <Row className="rowgap-vbox" gutter={[24, 0]}>
            <Col
              key={`Filter`}
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="mb-24"
            >
              <Card
                bordered={false}
                className="criclebox dashboard-filter-card-wrapper"
              >
                <Space
                  direction="vertical"
                  style={{ width: '100%' }}
                  size="large"
                >
                  <Space>
                    <Text>Filter</Text>
                    <Select
                      defaultValue="30"
                      style={{
                        width: 120,
                      }}
                      onChange={handleFilterByChange}
                      options={[
                        {
                          value: '30',
                          label: 'This Month',
                        },
                        {
                          value: '60',
                          label: 'Last 60 Days',
                        },
                        {
                          value: '90',
                          label: 'Last 90 Days',
                        },
                        {
                          value: 'custom',
                          label: 'Custom',
                        },
                      ]}
                    />
                    {customDateFilter && (
                      <RangePicker inline={true} onChange={handleDateChange} />
                    )}
                  </Space>
                  <Row
                    className="rowgap-vbox donation-filter-box-wrapper"
                    gutter={[24, 0]}
                  >
                    <Col
                      key={`Total Donation`}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      // className="mb-24"
                      className="donation-filter-box-wrapper_col"
                    >
                      <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                          <Col xs={24}>
                            <span>{`Total Donation`}</span>
                            <Title level={3}>
                              {dashboardLoading ? (
                                <DashboardCardSkeleton
                                  active
                                  paragraph={{ rows: 0 }}
                                  title={{ width: '50%' }}
                                />
                              ) : (
                                formatCurrencyINR(dashboardData?.amount)
                              )}
                            </Title>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col
                      key={`No. of Zakath`}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      // className="mb-24"
                      className="donation-filter-box-wrapper_col"
                    >
                      <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                          <Col xs={24}>
                            <span>{`No. of Zakath`}</span>
                            <Title level={3}>
                              {dashboardLoading ? (
                                <DashboardCardSkeleton
                                  active
                                  paragraph={{ rows: 0 }}
                                  title={{ width: '50%' }}
                                />
                              ) : (
                                dashboardData?.zakat
                              )}
                            </Title>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col
                      key={`No. of Sadaqa`}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      // className="mb-24"
                      className="donation-filter-box-wrapper_col"
                    >
                      <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                          <Col xs={24}>
                            <span>{`No. of Sadaqa`}</span>
                            <Title level={3}>
                              {dashboardLoading ? (
                                <DashboardCardSkeleton
                                  active
                                  paragraph={{ rows: 0 }}
                                  title={{ width: '50%' }}
                                />
                              ) : (
                                dashboardData?.sadaqa
                              )}
                            </Title>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col
                      key={`No. of New Donors`}
                      xs={12}
                      sm={12}
                      md={12}
                      lg={6}
                      xl={6}
                      // className="mb-24"
                      className="donation-filter-box-wrapper_col"
                    >
                      <div className="number">
                        <Row align="middle" gutter={[24, 0]}>
                          <Col xs={24}>
                            <span>{`No. of New Donors`}</span>
                            <Title level={3}>
                              {dashboardLoading ? (
                                <DashboardCardSkeleton
                                  active
                                  paragraph={{ rows: 0 }}
                                  title={{ width: '50%' }}
                                />
                              ) : (
                                dashboardData?.donee
                              )}
                            </Title>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Space>
              </Card>
            </Col>
          </Row>

          <Row className="rowgap-vbox" gutter={[24, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                {dashboardChartLoading ? (
                  <ChartSkeleton
                    active
                    paragraph={{ rows: 4, width: '100%' }}
                    title={{ width: '100%' }}
                  />
                ) : dashboardChartData?.length > 0 ? (
                  processedChartData && (
                    <LineChart config={processedChartData} />
                  )
                ) : (
                  'No data available'
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </DashboardWrapper>
    </AppLayout>
  );
};

export default Dashboard;
