import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row, Typography, QRCode } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import AppLayout from '../../components/layout/layout';
import {
  FieldSkeleton,
  MasjidProfileDescriptions,
  MasjidQrSkeleton,
  ProfileWrapper,
  QRWrapper,
  QrCodeFlex,
  QrPrintWrapper,
} from './profile-styles';
import { selectMasjidUuid, selectUserDetails } from '../login/auth-selectors';
import { PUBLIC_BASE_URL } from '../../../utils/constants';
import UpdateMasjidInfoDrawer from './update-masjid-info';
import { selectMasjidDetails, selectProfileLoading } from './profile-selectors';
import { fetchMasjidData } from './profile-slice';
import Pencil from '../../../icons/pencil';
import logo from '../../../assets/masjid-care/masjid-care-qr-image-logo-square.png';

const { Title, Text } = Typography;

const Profile = () => {
  const dispatch = useDispatch();
  const masjidUuid = useSelector(selectMasjidUuid);
  const userDetails = useSelector(selectUserDetails);
  const masjidDetails = useSelector(selectMasjidDetails);
  const profileLoading = useSelector(selectProfileLoading);

  const [showMasjidUpdateDrawer, setShowMasjidUpdateDrawer] = useState(false);

  const masjidSlug = PUBLIC_BASE_URL + 'masjids/' + masjidDetails?.slug;

  const handleMasjidUpdate = () => {
    setShowMasjidUpdateDrawer(!showMasjidUpdateDrawer);
  };

  const fetchMasjidDataFromAPi = async () => {
    await dispatch(fetchMasjidData({ masjidUid: masjidUuid }));
  };

  useEffect(() => {
    if (!masjidDetails) {
      fetchMasjidDataFromAPi();
    }
  }, []);

  return (
    <AppLayout>
      <ProfileWrapper>
        <Row gutter={[24, 0]}>
          <Col span={24} md={18} className="mb-24">
            <Card
              bordered={false}
              title={<h6 className="font-semibold m-0">Masjid Information</h6>}
              className="header-solid h-full card-profile-information darkbtn"
              extra={
                <Button
                  type="link"
                  className="darkbtn"
                  onClick={handleMasjidUpdate}
                >
                  <Pencil /> EDIT
                </Button>
              }
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <Row gutter={[24, 0]}>
                <Col span={24} md={16}>
                  <MasjidProfileDescriptions>
                    <MasjidProfileDescriptions.Item
                      label="Masjid Name"
                      span={3}
                    >
                      {profileLoading ? (
                        <FieldSkeleton
                          active
                          paragraph={false}
                          title={{ width: '50%' }}
                        />
                      ) : (
                        masjidDetails?.name
                      )}
                    </MasjidProfileDescriptions.Item>
                    <MasjidProfileDescriptions.Item
                      label="Landing Page"
                      span={3}
                    >
                      {profileLoading ? (
                        <FieldSkeleton
                          active
                          paragraph={false}
                          title={{ width: '50%' }}
                        />
                      ) : masjidDetails?.slug ? (
                        <a href={masjidSlug} target="_blank" rel="noreferrer">
                          {masjidSlug}
                        </a>
                      ) : (
                        'NIL'
                      )}
                    </MasjidProfileDescriptions.Item>
                    <MasjidProfileDescriptions.Item label="Address" span={3}>
                      {profileLoading ? (
                        <FieldSkeleton
                          active
                          paragraph={false}
                          title={{ width: '50%' }}
                        />
                      ) : (
                        masjidDetails?.address
                      )}
                    </MasjidProfileDescriptions.Item>
                    <MasjidProfileDescriptions.Item label="Phone" span={3}>
                      {masjidDetails?.phone ? (
                        <a href={`tel:${masjidDetails?.phone}`}>
                          {masjidDetails?.phone}
                        </a>
                      ) : (
                        'NIL'
                      )}
                    </MasjidProfileDescriptions.Item>
                    <MasjidProfileDescriptions.Item
                      label="POC (Point Of Contact)"
                      span={3}
                    >
                      {profileLoading ? (
                        <FieldSkeleton
                          active
                          paragraph={false}
                          title={{ width: '50%' }}
                        />
                      ) : (
                        masjidDetails?.poc
                      )}
                    </MasjidProfileDescriptions.Item>
                  </MasjidProfileDescriptions>
                </Col>
                <Col span={24} md={8} style={{ textAlign: 'center' }}>
                  {profileLoading ? (
                    <MasjidQrSkeleton.Avatar active shape="square" size={128} />
                  ) : (
                    masjidDetails?.slug && (
                      <QRWrapper>
                        <QRCode
                          bordered={false}
                          value={masjidSlug}
                          icon={logo}
                        />
                        <div
                          className="print-label-wrapper"
                          onClick={() => window?.print()}
                        >
                          <PrinterOutlined style={{ fontSize: '20px' }} />
                          Print QR
                        </div>
                      </QRWrapper>
                    )
                  )}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={24} md={6} className="mb-24 personal-information">
            <Card
              bordered={false}
              title={
                <h6 className="font-semibold m-0">Personal Information</h6>
              }
              className="header-solid h-full card-profile-information"
              // extra={<Button type="link">{pencil}</Button>}
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <MasjidProfileDescriptions>
                <MasjidProfileDescriptions.Item label="Full Name" span={3}>
                  {userDetails?.userName}
                </MasjidProfileDescriptions.Item>
                <MasjidProfileDescriptions.Item label="Email" span={3}>
                  {userDetails?.userEmail}
                </MasjidProfileDescriptions.Item>
              </MasjidProfileDescriptions>
            </Card>
          </Col>
        </Row>
      </ProfileWrapper>

      <UpdateMasjidInfoDrawer
        visible={showMasjidUpdateDrawer}
        onClose={handleMasjidUpdate}
      />
      {masjidDetails?.slug && (
        <QrPrintWrapper>
          <Title className="bold">MAKE A DONATION</Title>
          <QrCodeFlex>
            <QRCode bordered={false} value={masjidSlug} />
            <Text>
              <p>SCAN QR CODE, VISIT URL OR TAP WITH PHONE</p>
            </Text>
          </QrCodeFlex>
          <div className="qr-url">{masjidSlug}</div>
        </QrPrintWrapper>
      )}
    </AppLayout>
  );
};

export default Profile;
