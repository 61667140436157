import React from 'react';
import { Avatar } from 'antd';
import { getRandomColor } from '../../../utils';

const InitialsAvatar = ({ name }) => {
  const initials = name
    .split(' ')
    .map((part) => part.charAt(0))
    .join('')
    ?.toUpperCase();

  const backgroundColor = getRandomColor();

  return (
    <Avatar
      className="shape-avatar"
      shape="square"
      size={40}
      style={{ backgroundColor, verticalAlign: 'middle' }}
    >
      {initials}
    </Avatar>
  );
};

export default InitialsAvatar;
