import styled from 'styled-components';
import { Descriptions, Skeleton } from 'antd';

export const SettingWrapper = styled.div``;

export const RazorpayDescriptions = styled(Descriptions)`
  .ant-descriptions-item-container {
    display: flex;
    align-items: center;
  }
  .ant-descriptions-item-content {
    flex: 1;
  }
  .key_space {
    flex: 1;
    .ant-space-item:first-child {
      flex: 1;
      input {
        caret-color: transparent;
      }
    }
    .razorpay-copy-icon {
      svg {
        color: rgba(0, 0, 0, 0.45);
        &:hover {
          color: rgba(0, 0, 0, 1);
        }
      }
    }
  }
`;

export const FieldSkeleton = styled(Skeleton)``;

export const FormFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
