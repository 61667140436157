/**
 *
 * appRouter.js
 * file which hold application routers
 *
 * @author - Aftab Ahamed
 * @date - 12 April 2023
 *
 **/

// REGISTER COMPONENT IMPORT
// import { RegisterPage } from "../register";
// ROUTER PATH IMPORT
import React from 'react';
import PrivateRoute from '../view/components/private-route/private-route';
import Dashboard from '../view/pages/dashboard/dashboard';
import Donations from '../view/pages/donations/donations';
import Login from '../view/pages/login/login';
import PageNotFound from '../view/pages/page-not-found/page-not-found';
import Profile from '../view/pages/profile/profile';
import Settings from '../view/pages/settings/settings';
import * as routerPath from './constants';
// CREATE BROWSER ROUTER IMPORT
import { createBrowserRouter } from 'react-router-dom';
// ERROR COMPONENT IMPORT
// import { ErrorPage } from "../error";

// ROUTER CONSTANT
export const router = createBrowserRouter([
  {
    path: routerPath.LOGIN_PAGE_PATH,
    element: (
      <>
        <Login />
      </>
    ),
  },
  {
    path: routerPath.HOME_PAGE_PATH,
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  {
    path: routerPath.DASHBOARD_PAGE_PATH,
    element: (
      <PrivateRoute>
        <Dashboard />
      </PrivateRoute>
    ),
  },
  {
    path: routerPath.DONATIONS_PAGE_PATH,
    element: (
      <PrivateRoute>
        <Donations />
      </PrivateRoute>
    ),
  },
  {
    path: routerPath.PROFILE_PAGE_PATH,
    element: (
      <PrivateRoute>
        <Profile />
      </PrivateRoute>
    ),
  },
  {
    path: routerPath.SETTINGS_PAGE_PATH,
    element: (
      <PrivateRoute>
        <Settings />
      </PrivateRoute>
    ),
  },
  {
    path: routerPath.PAGE_NOT_FOUND_PATH,
    element: (
      <PrivateRoute>
        <PageNotFound />
      </PrivateRoute>
    ),
  },
]);
