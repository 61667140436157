import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from '../../../services/axiosService';
import { showNotification } from '../../../utils';

const initialState = {
  loading: false,
  donationsData: null,
  error: null,
};

export const fetchDonations = createAsyncThunk(
  'donations/fetchDonations',
  async () => {
    try {
      const response = await axiosService?.get('/donation?sort=-completed_at');
      return response?.data;
    } catch (error) {
      showNotification('error', error?.response?.data?.message);
      throw error?.response?.data?.message;
    }
  },
);

export const fetchDonationsByDate = createAsyncThunk(
  'donations/fetchDonationsByDate',
  async (dateRange) => {
    try {
      const response = await axiosService?.get(
        `/donation?filter[completed_at_between]=${dateRange}&sort=-completed_at`,
      );
      return response?.data;
    } catch (error) {
      showNotification('error', error?.response?.data?.message);
      throw error?.response?.data?.message;
    }
  },
);

export const exportDonationsData = createAsyncThunk(
  'donations/exportDonationsData',
  async ({ dateRange, type }) => {
    try {
      const response = await axiosService?.get(
        `/donation?filter[completed_at_between]=${dateRange}&export=${type}`,
      );
      window?.open(response?.data?.path);
      return response?.data;
    } catch (error) {
      showNotification('error', error?.response?.data?.message);
      throw error?.response?.data?.message;
    }
  },
);

export const downloadDonationReceipt = createAsyncThunk(
  'donations/downloadDonationReceipt',
  async ({ donationUuid }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PUBLIC_API_URL}donate/${donationUuid}/receipt`,
      );
      if (response?.ok) {
        const jsonResponse = await response?.json();
        window?.open(jsonResponse?.path);
      }
    } catch (error) {
      showNotification('error', error?.response?.data?.message);
      throw error?.response?.data?.message;
    }
  },
);

export const addDonationData = createAsyncThunk(
  'donations/addDonationData',
  async ({ postData }) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PUBLIC_API_URL}donate`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        },
      );
      if (!response.ok) {
        // Handle the error here
        console.error('Error: ', response.status);
        throw new Error(`HTTP Error: ${response.status}`);
      }
      return response.data;
    } catch (error) {
      showNotification(
        'error',
        error.response?.data?.message || 'An error occurred',
      );
      throw error?.response?.data?.message;
    }
  },
);
const donationsSlice = createSlice({
  name: 'DonationsSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchDonations.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDonations.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.donationsData = action.payload;
      })
      .addCase(fetchDonations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.donationsData = null;
      })
      .addCase(fetchDonationsByDate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDonationsByDate.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.donationsData = action.payload;
      })
      .addCase(fetchDonationsByDate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.donationsData = null;
      });
  },
});

export default donationsSlice.reducer;
