import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosService from '../../../services/axiosService';
import { showNotification } from '../../../utils';

const initialState = {
  loading: false,
  chartLoading: false,
  dashboardData: null,
  dashboardChartData: null,
  error: null,
};

export const fetchDashboardData = createAsyncThunk(
  'donations/fetchDashboardData',
  async ({ dateRange } = {}) => {
    try {
      let response;
      if (dateRange) {
        response = await axiosService?.get(`/donation/statistics${dateRange}`);
      } else {
        response = await axiosService?.get(`/donation/statistics`);
      }

      return response?.data;
    } catch (error) {
      showNotification('error', error?.response?.data?.message);
      throw error?.response?.data?.message;
    }
  },
);

export const fetchDashboardChartData = createAsyncThunk(
  'donations/fetchDashboardChartData',
  async (dateRange) => {
    try {
      const response = await axiosService?.get(
        `/donation?filter[completed_at_between]=${dateRange}&sort=-completed_at`,
      );
      return response?.data;
    } catch (error) {
      showNotification('error', error?.response?.data?.message);
      throw error?.response?.data?.message;
    }
  },
);

const dashboardSlice = createSlice({
  name: 'DashboardSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.dashboardData = action.payload;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
        state.dashboardData = null;
      })
      .addCase(fetchDashboardChartData.pending, (state) => {
        state.chartLoading = true;
        state.error = null;
      })
      .addCase(fetchDashboardChartData.fulfilled, (state, action) => {
        state.chartLoading = false;
        state.error = null;
        state.dashboardChartData = action.payload;
      })
      .addCase(fetchDashboardChartData.rejected, (state, action) => {
        state.chartLoading = false;
        state.error = action.error.message;
        state.dashboardChartData = null;
      });
  },
});

export default dashboardSlice.reducer;
