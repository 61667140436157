import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosService from '../../../services/axiosService';
import { showNotification } from '../../../utils';
import localStorageService from '../../../services/localStorageService';
import { MASJID } from '../../../utils/constants';

const initialState = {
  loading: false,
  masjidData: null,
  error: null,
};

export const updateMasjidData = createAsyncThunk(
  'profile/postMasjidData',
  async ({ masjidUid, postData }) => {
    try {
      const response = await axiosService.post(
        `/masjid/${masjidUid}/update`,
        postData,
      );
      if (response?.data) {
        // console.log(response?.data, "response?.data from updateMasjidData");
        localStorageService?.setItem(MASJID, response?.data);
      }
      return response.data;
    } catch (error) {
      showNotification('error', error?.response?.data?.message);
      throw error?.response?.data?.message;
    }
  },
);

export const fetchMasjidData = createAsyncThunk(
  'profile/fetchMasjidData',
  async ({ masjidUid }) => {
    try {
      const response = await axiosService.get(`/masjid/${masjidUid}`);
      if (response?.data) {
        localStorageService?.setItem(MASJID, response?.data);
      }
      return response.data;
    } catch (error) {
      showNotification('error', error?.response?.data?.message);
      throw error?.response?.data?.message;
    }
  },
);

const profileSlice = createSlice({
  name: 'ProfileSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchMasjidData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMasjidData.fulfilled, (state, action) => {
        state.loading = false;
        state.masjidData = action?.payload;
      })
      .addCase(fetchMasjidData.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

export default profileSlice.reducer;
