import React from 'react';
import { StatusWrapper } from './status-styles';

const Status = ({ children }) => {
  return (
    <StatusWrapper className={children?.toLowerCase()}>
      {children}
    </StatusWrapper>
  );
};

export default Status;
