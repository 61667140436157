/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import { Row, Col, Breadcrumb, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';

function Header({ name, onPress }) {
  // console.log(name, "name");

  return (
    <>
      <Row gutter={[24, 0]} align="bottom">
        <Col span={12} md={22}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Pages</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ textTransform: 'capitalize' }}>
              {name?.replace('/', '')}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <Col span={12} md={2} className="header-control">
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            <MenuOutlined />
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default Header;
