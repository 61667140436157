import styled from 'styled-components';
import { Skeleton } from 'antd';

export const DashboardWrapper = styled.div`
  .dashboard-filter-card-wrapper {
    .ant-card-body {
      .ant-space {
        @media only screen and (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
`;

export const DashboardCardSkeleton = styled(Skeleton)`
  .ant-skeleton-content {
    display: flex !important;
    align-items: end;
  }
`;

export const ChartSkeleton = styled(Skeleton)``;
