import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../view/pages/login/auth-slice';
import donationsReducer from '../view/pages/donations/donations-slice';
import dashboardReducer from '../view/pages/dashboard/dashboard-slice';
import profileReducer from '../view/pages/profile/profile-slice';
import settingsReducer from '../view/pages/settings/settings-slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    donations: donationsReducer,
    dashboard: dashboardReducer,
    profile: profileReducer,
    settings: settingsReducer,
  },
});
