import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosService from '../../../services/axiosService';
import { showNotification } from '../../../utils';
import localStorageService from '../../../services/localStorageService';
import { MASJID_UUID, TOKEN, USER } from '../../../utils/constants';

const initialState = {
  loading: false,
  error: null,
  token: null,
  masjidUuid: null,
  userData: null,
};

export const loginAsync = createAsyncThunk(
  'auth/loginAsync',
  async (credentials) => {
    try {
      const response = await axiosService.post(
        '/login?include=masjid',
        credentials,
      );
      if (response?.data?.token) {
        localStorageService?.setItem(TOKEN, response?.data?.token);
        localStorageService?.setItem(MASJID_UUID, response?.data?.masjid?.uuid);
        localStorageService?.setItem(USER, {
          userName: response?.data?.name,
          userEmail: response?.data?.email,
        });
      }
      return response.data;
    } catch (error) {
      showNotification('error', error?.response?.data?.error);
      throw error?.response?.data?.message;
    }
  },
);

const authSlice = createSlice({
  name: 'AuthSlice',
  initialState,
  reducers: {
    logout: (state) => {
      state.token = '';
    },
    initializeToken: (state) => {
      const storedToken = localStorageService?.getItem(TOKEN);
      const storedMasjidUuid = localStorageService?.getItem(MASJID_UUID);
      const storedUser = localStorageService?.getItem(USER);
      if (storedToken) {
        state.token = storedToken;
        state.userData = storedUser;
        state.masjidUuid = storedMasjidUuid;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.token = action?.payload?.token;
        state.masjidUuid = action?.payload?.masjid?.uuid;
        state.userData = {
          userName: action?.payload?.name,
          userEmail: action?.payload?.email,
        };
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { logout, initializeToken } = authSlice.actions;
export default authSlice.reducer;
