import React, { useEffect, useState } from 'react';
import AppLayout from '../../components/layout/layout';
import {
  FieldSkeleton,
  RazorpayDescriptions,
  SettingWrapper,
} from './settings-styles';
import { Button, Card, Col, Row, Input, Space } from 'antd';
import Pencil from '../../../icons/pencil';
import { copyToClipboard } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettingsData } from './settings-slice';
import { selectMasjidUuid } from '../login/auth-selectors';
import {
  selectSettingsData,
  selectSettingsLoading,
} from './settings-selectors';
import UpdateRazorpayInfoDrawer from './update-razorpay-info';
import { CopyOutlined } from '@ant-design/icons';

const Settings = () => {
  const dispatch = useDispatch();
  const masjidUuid = useSelector(selectMasjidUuid);
  const settingsLoading = useSelector(selectSettingsLoading);
  const settingsData = useSelector(selectSettingsData);
  const [razorpayKeyVisible, setRazorpayKeyVisible] = useState(false);
  const [razorpaySecretVisible, setRazorpaySecretVisible] = useState(false);
  const [showRazorpayUpdateDrawer, setShowRazorpayUpdateDrawer] =
    useState(false);

  const handleRazorpayUpdate = () => {
    setShowRazorpayUpdateDrawer(!showRazorpayUpdateDrawer);
  };

  const fetchSettingsDataFromAPi = async () => {
    await dispatch(fetchSettingsData({ masjidUid: masjidUuid }));
  };

  const handleCta = (key, secret, loading) => {
    if (loading) {
      return '';
    } else if (key && secret) {
      return (
        <Button type="link" className="darkbtn" onClick={handleRazorpayUpdate}>
          <Pencil /> EDIT
        </Button>
      );
    } else {
      return (
        <Button type="primary" onClick={handleRazorpayUpdate}>
          ADD RAZORPAY DETAILS
        </Button>
      );
    }
  };

  useEffect(() => {
    if (!settingsData) {
      fetchSettingsDataFromAPi();
    }
  }, [settingsData]);

  return (
    <AppLayout>
      <SettingWrapper>
        <Row gutter={[24, 0]}>
          <Col span={24} md={12}>
            <Card
              bordered={false}
              title={<h6 className="font-semibold m-0">Razorpay Settings</h6>}
              className="header-solid h-full card-profile-information darkbtn"
              extra={handleCta(
                settingsData?.razorpay_key,
                settingsData?.razorpay_secret,
                settingsLoading,
              )}
              bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
            >
              {settingsLoading ? (
                <FieldSkeleton
                  active
                  paragraph={{ rows: 2, width: '100%' }}
                  title={false}
                />
              ) : (
                <RazorpayDescriptions>
                  {settingsData?.razorpay_key && (
                    <RazorpayDescriptions.Item label="Razorpay Key" span={3}>
                      <Space direction="horizontal" className="key_space">
                        <Input.Password
                          placeholder="Razorpay Key"
                          visibilityToggle={{
                            visible: razorpayKeyVisible,
                            onVisibleChange: setRazorpayKeyVisible,
                          }}
                          value={settingsData?.razorpay_key}
                          bordered={false}
                        />
                        <div className="razorpay-copy-icon">
                          <CopyOutlined
                            onClick={() =>
                              copyToClipboard(settingsData?.razorpay_key)
                            }
                          />
                        </div>
                      </Space>
                    </RazorpayDescriptions.Item>
                  )}
                  {settingsData?.razorpay_secret && (
                    <RazorpayDescriptions.Item label="Razorpay Secret" span={3}>
                      <Space direction="horizontal" className="key_space">
                        <Input.Password
                          placeholder="Razorpay Secret"
                          visibilityToggle={{
                            visible: razorpaySecretVisible,
                            onVisibleChange: setRazorpaySecretVisible,
                          }}
                          value={settingsData?.razorpay_secret}
                          bordered={false}
                        />
                        <div className="razorpay-copy-icon">
                          <CopyOutlined
                            onClick={() =>
                              copyToClipboard(settingsData?.razorpay_secret)
                            }
                          />
                        </div>
                      </Space>
                    </RazorpayDescriptions.Item>
                  )}
                </RazorpayDescriptions>
              )}
            </Card>
          </Col>
        </Row>
      </SettingWrapper>

      <UpdateRazorpayInfoDrawer
        visible={showRazorpayUpdateDrawer}
        onClose={handleRazorpayUpdate}
      />
    </AppLayout>
  );
};

export default Settings;
