import styled from 'styled-components';

export const LoginWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  /* background: rgb(240, 242, 244); */
  max-width: 1000px;
  margin: 0 auto;
  gap: 50px;
`;

export const FormWrapper = styled.div`
  flex: 1;
  max-width: 450px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 20px 27px rgb(0 0 0/5%);
  padding: 20px;
  border-radius: 6px;
  .masjid-care-logo {
    text-align: center;
    img {
      margin-bottom: 20px;
      height: 50px;
    }
  }
  .title {
    margin-bottom: 20px;
  }
`;
