import styled from 'styled-components';

export const SideNavWrapper = styled.div`
  /* .logout-wrapper {
    position: fixed;
    bottom: 0;
    color: red;
    display: flex;
    padding: 10px 16px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    padding: 10px 16px;
    cursor: pointer;
    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
    }
    span.icon {
      display: inline-flex;
      width: 32px;
      height: 32px;
      background-color: #fff;
      box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
      border-radius: 6px;
      justify-content: center;
      align-items: center;
      margin-right: 11px;
      vertical-align: middle;
    }
  } */
  .footer-menu {
    li {
      .handle-logout-wrapper {
        padding: 10px 16px;
        cursor: pointer;
        /* .icon {
          background-color: red !important;
          svg {
            path {
              fill: #fff !important;
            }
          }
        } */
        .label {
          color: red;
        }
      }
    }
  }
`;

export const LogoutHandleWrapper = styled.div``;
