import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  FilterOutlined,
  DownloadOutlined,
  HeartTwoTone,
} from '@ant-design/icons';
import AppLayout from '../../components/layout/layout';
import {
  downloadDonationReceipt,
  exportDonationsData,
  fetchDonations,
  fetchDonationsByDate,
} from './donations-slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Table,
  Typography,
  DatePicker,
  Space,
} from 'antd';
import {
  selectDonationsData,
  selectDonationsLoading,
} from './donations-selectors';
import { capitalize, formatCurrencyINR } from '../../../utils';
import InitialsAvatar from '../../components/initials-avatar/initials-avatar';
import { DonationsWrapper, ExportIconsWrapper } from './donations-styles';
import Status from '../../components/status/status';
import DonationsFilterDrawer from './donations-filter-drawer';
import AddDonationForm from './add-donation-form';

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'DATE & ORDER ID',
    dataIndex: 'date',
    key: 'date',
    width: '20%',
  },
  {
    title: 'DONOR',
    dataIndex: 'donor',
    key: 'donor',
    width: '20%',
  },
  {
    title: 'AMOUNT',
    dataIndex: 'amount',
    key: 'amount',
    width: '20%',
  },

  {
    title: 'TYPE',
    key: 'type',
    dataIndex: 'type',
    width: '20%',
  },
  {
    title: 'STATUS',
    key: 'status',
    dataIndex: 'status',
    width: '20%',
  },
];

const Donations = () => {
  const dispatch = useDispatch();
  const donations = useSelector(selectDonationsData);
  const donationLoading = useSelector(selectDonationsLoading);

  const [donationTableData, setDonationTableData] = useState([]);
  const [exportQuery, setExportQuery] = useState('');

  const [showFilterDrawer, setShowFilterDrawer] = useState(false);

  const [showAddDonationDrawer, setShowAddDonationDrawer] = useState(false);

  const handleDateChange = (date, dateString) => {
    const query = dateString[0] + ',' + dateString[1];
    if (date) {
      dispatch(fetchDonationsByDate(query));
      setExportQuery(query);
    } else {
      dispatch(fetchDonations());
      setExportQuery('');
    }
  };

  const handleExport = (type) => {
    dispatch(exportDonationsData({ dateRange: exportQuery, type }));
  };

  const handleDownloadReceipt = (donationUuid) => {
    dispatch(downloadDonationReceipt({ donationUuid }));
  };

  const handleAddDonation = () => {
    setShowAddDonationDrawer(!showAddDonationDrawer);
  };

  useEffect(() => {
    if (donations === null) {
      dispatch(fetchDonations());
    }
  }, [donations]);

  useEffect(() => {
    let tempDonationData = [];
    tempDonationData =
      donations &&
      donations?.length > 0 &&
      donations?.map((donation) => {
        return {
          key: donation?.uuid,
          date: (
            <>
              {moment(donation?.created_at)?.format('MMMM D, YYYY')}{' '}
              <small>{moment(donation?.created_at)?.format('h:mm A')}</small>
              <br />
              <small>
                {donation?.razorpay__order__id?.replace('order_', '')}
              </small>
            </>
          ),
          donor: (
            <>
              <Avatar.Group>
                <InitialsAvatar name={donation?.name} />
                <div className="avatar-info">
                  <Title level={5}>{donation?.name}</Title>
                  <>
                    {donation?.phone && (
                      <>
                        <small>{donation?.phone}</small>
                        <br />
                      </>
                    )}
                    {donation?.email && <small>{donation?.email}</small>}
                  </>
                </div>
              </Avatar.Group>
            </>
          ),
          amount: (
            <>
              {formatCurrencyINR(donation?.amount)}{' '}
              {donation?.is_transaction_covered ? (
                <>
                  <HeartTwoTone twoToneColor="red" />
                  <br />
                  <small>
                    Includes Charges :{' '}
                    {formatCurrencyINR(donation?.is_transaction_covered)}
                  </small>
                </>
              ) : (
                ''
              )}
            </>
          ),
          type: capitalize(donation?.type),
          status: (
            <Space>
              <Status>{donation?.status?.toUpperCase()}</Status>
              {donation?.status === 'completed' && (
                <DownloadOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDownloadReceipt(donation?.uuid)}
                />
              )}
            </Space>
          ),
        };
      });
    setDonationTableData(tempDonationData);
  }, [donations]);

  return (
    <AppLayout>
      <DonationsWrapper>
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24 donations-filter-card-wrapper"
              title="Donations"
              extra={
                <>
                  <Space>
                    <Text>Filter</Text>
                    <RangePicker onChange={handleDateChange} />
                    {donationTableData?.length > 0 ? (
                      <>
                        <Button type="link" onClick={() => handleExport('pdf')}>
                          EXPORT AS PDF
                        </Button>
                        <Button
                          type="link"
                          onClick={() => handleExport('xlsx')}
                        >
                          EXPORT AS EXCEL
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button type="link" disabled>
                          EXPORT AS PDF
                        </Button>
                        <Button type="link" disabled>
                          EXPORT AS EXCEL
                        </Button>
                      </>
                    )}
                  </Space>
                  <div className="mobile-donation-filter-icon">
                    {donationTableData?.length > 0 ? (
                      <ExportIconsWrapper>
                        <div onClick={() => handleExport('pdf')}>
                          <DownloadOutlined style={{ fontSize: '20px' }} /> PDF
                        </div>
                        <div onClick={() => handleExport('xlsx')}>
                          <DownloadOutlined style={{ fontSize: '20px' }} />{' '}
                          Excel
                        </div>
                      </ExportIconsWrapper>
                    ) : (
                      ''
                    )}
                    <FilterOutlined
                      onClick={() => setShowFilterDrawer(true)}
                      style={{ fontSize: '20px' }}
                    />
                  </div>
                  <Space>
                    <Button type="primary" onClick={handleAddDonation}>
                      ADD DONATION
                    </Button>
                  </Space>
                </>
              }
            >
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={donationTableData}
                  pagination={{ position: ['bottomCenter'] }}
                  className="ant-border-space"
                  loading={donationLoading}
                  locale={{ emptyText: 'No donations found' }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </DonationsWrapper>
      <DonationsFilterDrawer
        visible={showFilterDrawer}
        onClose={() => setShowFilterDrawer(!showFilterDrawer)}
        handleDateChange={handleDateChange}
      />
      <AddDonationForm
        visible={showAddDonationDrawer}
        onClose={handleAddDonation}
      />
    </AppLayout>
  );
};

export default React.memo(Donations);
