import styled from 'styled-components';
import { Descriptions, Skeleton } from 'antd';

export const ProfileWrapper = styled.div`
  @media print {
    display: none;
  }
`;

export const FormFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FieldSkeleton = styled(Skeleton)`
  .ant-skeleton-title {
    margin: 0;
  }
`;

export const MasjidProfileDescriptions = styled(Descriptions)`
  .ant-descriptions-item-content {
    align-items: center !important;
  }
`;

export const MasjidQrSkeleton = styled(Skeleton)``;

export const QRWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  .print-label-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #1677ff;
    text-transform: uppercase;
  }
`;

export const QrPrintWrapper = styled.div`
  display: none;
  @media print {
    display: block;
  }
  max-width: 350px;
  margin: 0 auto;
  .qr-url {
    width: 100%;
    text-align: center;
  }
`;

export const QrCodeFlex = styled.div`
  display: flex;
  align-items: center;
  .ant-qrcode {
    width: 100% !important;
    padding: 0;
  }

  /* gap: 30px; */
`;
