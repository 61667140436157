/**
 *
 * constant.js
 * file which hold router path
 *
 * @author - Aftab Ahamed
 * @date - 12 April 2023
 *
 **/

// ROUTES CONSTANTS
export const LOCAL_STORAGE_PREFIX = 'masjid-care-';
export const LOGIN_PAGE_PATH = '/login';
export const HOME_PAGE_PATH = '/';
export const DASHBOARD_PAGE_PATH = '/dashboard';
export const DONATIONS_PAGE_PATH = '/donations';
export const PROFILE_PAGE_PATH = '/profile';
export const SETTINGS_PAGE_PATH = '/settings';

export const PAGE_NOT_FOUND_PATH = '*';
