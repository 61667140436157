import React, { useState } from 'react';
import { Drawer, Button, Input, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FormFooterWrapper } from './profile-styles';
import { selectMasjidDetails } from './profile-selectors';
import { fetchMasjidData, updateMasjidData } from './profile-slice';

const UpdateMasjidInfoDrawer = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const masjidDetails = useSelector(selectMasjidDetails);

  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  const initialValues = {
    masjidName: masjidDetails?.name,
    masjidPhone: masjidDetails?.phone,
    masjidAddress: masjidDetails?.address,
    masjidPoc: masjidDetails?.poc,
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const updatedValues = await form.validateFields();
      const postData = {
        name: updatedValues?.masjidName,
        poc: updatedValues?.masjidPoc,
        address: updatedValues?.masjidAddress,
        phone: updatedValues?.masjidPhone,
      };
      await dispatch(
        updateMasjidData({ masjidUid: masjidDetails?.uuid, postData }),
      );
      await dispatch(fetchMasjidData({ masjidUid: masjidDetails?.uuid }));
      setIsSaving(false);
      onClose();
    } catch (error) {
      setIsSaving(false);
    }
  };

  return (
    <Drawer
      title="Update Masjid Information"
      width={400}
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <FormFooterWrapper>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} type="primary" loading={isSaving}>
            Save Info
          </Button>
        </FormFooterWrapper>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="updateInfoForm"
        initialValues={initialValues}
      >
        <Form.Item
          name="masjidName"
          label="Masjid Name"
          rules={[
            {
              required: true,
              message: 'Please enter a name',
            },
            {
              max: 255,
              message: 'Name must not exceed 255 characters',
            },
          ]}
        >
          <Input placeholder="Masjid Name" autoComplete="off" />
        </Form.Item>

        <Form.Item
          name="masjidPhone"
          label="Masjid Phone"
          rules={[
            {
              required: true,
              message: 'Please enter a phone number',
            },
            {
              max: 10,
              message: 'Phone number must not exceed 10 characters',
            },
          ]}
        >
          <Input placeholder="Masjid Phone" autoComplete="off" />
        </Form.Item>

        <Form.Item
          name="masjidPoc"
          label="Point of Contact (POC)"
          rules={[
            {
              required: true,
              message: 'Please enter a POC',
            },
            {
              max: 255,
              message: 'POC must not exceed 255 characters',
            },
          ]}
        >
          <Input placeholder="Point of Contact (POC)" autoComplete="off" />
        </Form.Item>

        <Form.Item
          name="masjidAddress"
          label="Masjid Address"
          rules={[
            {
              required: true,
              message: 'Please enter an address',
            },
            {
              max: 5000,
              message: 'Address must not exceed 5000 characters',
            },
          ]}
        >
          <Input.TextArea
            placeholder="Masjid Address"
            rows={4}
            autoComplete="off"
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateMasjidInfoDrawer;
