import styled from 'styled-components';

export const DonationsWrapper = styled.div`
  .donations-filter-card-wrapper {
    .ant-card-head {
      .ant-card-head-wrapper {
        .ant-card-extra {
          .mobile-donation-filter-icon {
            display: none;
          }
          @media only screen and (max-width: 768px) {
            .ant-space {
              display: none;
            }
            .mobile-donation-filter-icon {
              display: flex;
              align-items: center;
              gap: 15px;
            }
          }
        }
      }
    }
  }
  .avatar-info {
    line-height: normal;
    h5 {
      margin-bottom: 5px;
    }
  }
`;

export const DonationsFilterDrawerWrapper = styled.div``;

export const ExportIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  div {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
  }
`;
export const FormFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
