import React, { useState } from 'react';
import { Drawer, Button, Input, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FormFooterWrapper } from './settings-styles';
// import { fetchMasjidData, updateMasjidData } from "./profile-slice";
import { selectSettingsData } from './settings-selectors';
import { selectMasjidUuid } from '../login/auth-selectors';
import { fetchSettingsData, updateRazorpayData } from './settings-slice';

const UpdateRazorpayInfoDrawer = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const masjidUuid = useSelector(selectMasjidUuid);
  const settingsData = useSelector(selectSettingsData);

  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  const initialValues = {
    razorpayKey: settingsData?.razorpay_key,
    razorpaySecret: settingsData?.razorpay_secret,
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const updatedValues = await form.validateFields();
      const postData = {
        razorpay_key: updatedValues?.razorpayKey,
        razorpay_secret: updatedValues?.razorpaySecret,
      };
      await dispatch(
        updateRazorpayData({ settingsUuid: settingsData?.uuid, postData }),
      );
      await dispatch(fetchSettingsData({ masjidUid: masjidUuid }));
      setIsSaving(false);
      onClose();
    } catch (error) {
      setIsSaving(false);
    }
  };

  return (
    <Drawer
      title={`${
        initialValues?.razorpayKey && initialValues?.razorpaySecret
          ? 'Update Razorpay Info'
          : 'Add Razorpay Details'
      }`}
      width={400}
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <FormFooterWrapper>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} type="primary" loading={isSaving}>
            Save Info
          </Button>
        </FormFooterWrapper>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="updateInfoForm"
        initialValues={initialValues}
      >
        <Form.Item
          name="razorpayKey"
          label="Razorpay Key"
          rules={[
            {
              required: true,
              message: 'Please enter razorpay key',
            },
            {
              max: 255,
              message: 'Name must not exceed 255 characters',
            },
          ]}
        >
          <Input.Password placeholder="Razorpay Key" autoComplete="off" />
        </Form.Item>

        <Form.Item
          name="razorpaySecret"
          label="Razorpay Secret"
          rules={[
            {
              required: true,
              message: 'Please enter razorpay secret',
            },
            {
              max: 255,
              message: 'Name must not exceed 255 characters',
            },
          ]}
        >
          <Input.Password placeholder="Razorpay Secret" autoComplete="off" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UpdateRazorpayInfoDrawer;
