import axios from 'axios';
import localStorageService from './localStorageService';
import { TOKEN } from '../utils/constants';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Replace with your actual base URL
  timeout: 10000, // Adjust the timeout as needed
  // You can also add other headers, interceptors, etc. here
});

// Add an interceptor to include the Authorization header with the token
instance.interceptors.request.use((config) => {
  const token = localStorageService.getItem(TOKEN);
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const axiosService = {
  post: (url, data) => instance.post(url, data),
  get: (url, params) => instance.get(url, { params }),
  put: (url, data) => instance.put(url, data),
  delete: (url) => instance.delete(url),
};

export default axiosService;
