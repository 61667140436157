import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';
import 'antd/dist/reset.css';
// theme css
import './styles/main.css';
import './styles/responsive.css';
import GlobalStyle from './styles/globalStyles';
import { initializeToken } from './view/pages/login/auth-slice';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Dispatch the action to initialize the token
store.dispatch(initializeToken());

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <GlobalStyle />
    <App />
  </Provider>,
  // </React.StrictMode>
);
