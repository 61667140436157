/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Typography } from 'antd';
import lineChart from './configs/line-chart-config';

function LineChart({ config = null }) {
  const { Title } = Typography;

  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Sadaqa (vs) Zakath : (Last 30 Days)</Title>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={config ? config?.options : lineChart.options}
        series={config ? config?.series : lineChart.series}
        type="area"
        height={350}
        width={'100%'}
      />
    </>
  );
}

export default LineChart;
