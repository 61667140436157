import React, { useState } from 'react';
import { Drawer, Button, Input, Form, Select, InputNumber } from 'antd';
import { FormFooterWrapper } from './donations-styles';
import { selectMasjidDetails } from '../profile/profile-selectors';
import { useDispatch, useSelector } from 'react-redux';
import { addDonationData, fetchDonations } from './donations-slice';

const AddDonationForm = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const masjidDetails = useSelector(selectMasjidDetails);
  const [form] = Form.useForm();
  const [isSaving, setIsSaving] = useState(false);

  const initialValues = {
    donorName: '',
    donorEmail: '',
    donorPhone: '',
    donationType: '',
    donorAmount: 0,
  };

  const handleSave = async () => {
    try {
      setIsSaving(true);
      const updatedValues = await form.validateFields();
      const postData = {
        name: updatedValues?.donorName,
        email: updatedValues?.donorEmail,
        phone: updatedValues?.donorPhone,
        type: updatedValues?.donationType,
        amount: updatedValues?.donorAmount,
        slug: masjidDetails?.slug,
        is_transaction_covered: 0,
        is_manual: 1,
      };
      await dispatch(addDonationData({ postData }));
      await dispatch(fetchDonations());
      setIsSaving(false);
      onClose();
    } catch (error) {
      setIsSaving(false);
    }
  };

  return (
    <Drawer
      title={`Add Donation`}
      width={400}
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <FormFooterWrapper>
          <Button onClick={onClose} style={{ marginRight: 8 }}>
            Cancel
          </Button>
          <Button onClick={handleSave} type="primary" loading={isSaving}>
            Add Donation
          </Button>
        </FormFooterWrapper>
      }
    >
      <Form
        form={form}
        layout="vertical"
        name="addDonationForm"
        initialValues={initialValues}
      >
        <Form.Item
          name="donorName"
          label="Donor Name"
          rules={[
            {
              required: true,
              message: 'Please enter the donor name',
            },
          ]}
        >
          <Input placeholder="Donor Name" autoComplete="off" />
        </Form.Item>

        <Form.Item
          name="donorEmail"
          label="Donor Email"
          rules={[
            {
              type: 'email',
              message: 'Please enter a valid email address',
            },
            {
              required: true,
              message: 'Please enter the donor email',
            },
          ]}
        >
          <Input placeholder="Donor Email" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="donorPhone"
          label="Donor Phone"
          rules={[
            {
              required: true,
              message: 'Please enter the donor phone number',
            },
          ]}
        >
          <Input placeholder="Donor Phone" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="donationType"
          label="Donation Type"
          rules={[
            {
              required: true,
              message: 'Please select the donation type',
            },
          ]}
        >
          <Select placeholder="Donation Type">
            <Select.Option value="sadaqa">Sadaqa</Select.Option>
            <Select.Option value="zakat">Zakat</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="donorAmount"
          label="Donation Amount"
          rules={[
            {
              required: true,
              message: 'Please enter the donation amount',
            },
            {
              type: 'number',
              min: 100,
              message: 'Donation must be a minimum of 100',
            },
          ]}
        >
          <InputNumber placeholder="Amount" autoComplete="off" />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default AddDonationForm;
