/**
 *
 * constant.js
 * file which holds application constants
 *
 * @author - Aftab Ahamed
 * @date - 27 August 2023
 *
 **/

export const PUBLIC_BASE_URL = 'https://masjidcare.in/';
export const TOKEN = 'token';
export const MASJID = 'masjid';
export const USER = 'user';
export const MASJID_UUID = 'masjid-uuid';
