import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosService from '../../../services/axiosService';
import { showNotification } from '../../../utils';

const initialState = {
  loading: false,
  settingsData: null,
  error: null,
};

export const updateRazorpayData = createAsyncThunk(
  'settings/updateRazorpayData',
  async ({ settingsUuid, postData }) => {
    try {
      const response = await axiosService.post(
        `/masjid/setting/${settingsUuid}/update`,
        postData,
      );
      return response.data;
    } catch (error) {
      showNotification('error', error?.response?.data?.message);
      throw error?.response?.data?.message;
    }
  },
);

export const fetchSettingsData = createAsyncThunk(
  'settings/fetchSettingsData',
  async ({ masjidUid }) => {
    try {
      const response = await axiosService.get(`/masjid/${masjidUid}/setting`);
      return response?.data;
    } catch (error) {
      showNotification('error', error?.response?.data?.message);
      throw error?.response?.data?.message;
    }
  },
);

const settingsSlice = createSlice({
  name: 'SettingsSlice',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchSettingsData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSettingsData.fulfilled, (state, action) => {
        state.loading = false;
        state.settingsData = action?.payload;
      })
      .addCase(fetchSettingsData.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

export default settingsSlice.reducer;
