import React from 'react';
import { DonationsFilterDrawerWrapper } from './donations-styles';
import { Button, DatePicker, Drawer, Form } from 'antd';
import { FormFooterWrapper } from '../profile/profile-styles';

const DonationsFilterDrawer = ({ visible, onClose, handleDateChange }) => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  //   const [isSaving, setIsSaving] = useState(false);

  return (
    <DonationsFilterDrawerWrapper>
      <Drawer
        title="Filter Donations"
        width={400}
        onClose={onClose}
        open={visible}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <FormFooterWrapper>
            <Button onClick={onClose} style={{ marginRight: 8 }}>
              Cancel
            </Button>
            <Button onClick={onClose} type="primary">
              Apply
            </Button>
          </FormFooterWrapper>
        }
      >
        <Form
          form={form}
          layout="vertical"
          name="updateInfoForm"
          //   initialValues={initialValues}
        >
          <Form.Item label="Filter by date" name="dateRange">
            <RangePicker onChange={handleDateChange} />
          </Form.Item>
        </Form>
      </Drawer>
    </DonationsFilterDrawerWrapper>
  );
};

export default DonationsFilterDrawer;
