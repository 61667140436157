import { notification } from 'antd';

export const showNotification = (type = 'info', message, description) => {
  notification[type]({
    message,
    description: description || '',
  });
};

export const capitalize = (str) => {
  str = str.toLowerCase();
  return str?.[0]?.toUpperCase() + str?.substring(1);
};
export const getRandomColor = () => {
  const colors = [
    '#f56a00',
    '#7265e6',
    '#ffbf00',
    '#00a2ae',
    '#ff4d4f',
    '#1890ff',
    '#36cfc9',
    '#fadb14',
  ];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export const formatCurrencyINR = (number) => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  }).format(number);
};

export const copyToClipboard = (text) => {
  navigator?.clipboard
    ?.writeText(text)
    .then(() => {
      showNotification('success', `Copied to clipboard`);
      // console.log('Text copied to clipboard:', text);
    })
    .catch((error) => {
      showNotification('error', `Error copying text to clipboard`, error);
      // console.error("Error copying text to clipboard:", error);
    });
};
